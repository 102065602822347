<template>
	<van-nav-bar title="追溯查询结果" left-arrow @click-left="onClickLeft" fixed placeholder />
	<Trace />
	<div class="tools btn">
		<van-button block @click="continueQuery" type="primary" round>继续查询</van-button>
		<van-button block @click="backHome" color="#345345" round plain>返回首页</van-button>
	</div>
</template>

<script setup>
import Trace from './Trace.vue'
import { useRouter } from 'vue-router'
const router = useRouter()
// const route = useRoute();

const onClickLeft = () => {
	router.back()
}
// const { p, site_code } = route.query;
const backHome = () => {
	router.push({
		path: '/',
		query: {
      p: localStorage.getItem('encryptP').replace(/^\s+|\s+$/g, ''),
      site_code: localStorage.getItem('encryptSiteCode').replace(/^\s+|\s+$/g, ''),
      t: Math.floor(Math.random() * 9999999999),
			tabIndex: 0
		}
	})
}
const continueQuery = () => {
	router.back()
}
</script>
<style>
.tools {
	padding: 30px;
	box-sizing: border-box;
	background: #fff;
}
.btn > button:first-child {
	margin-bottom: 16px;
}
</style>
